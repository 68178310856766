<template>
    <div class="container">
        <div class="plan-house">
            <p class="titlePlanHouse">{{ $t('servicePlanHouse.title1')}}</p>
            <p class="subtitlePlanHouse">{{ $t('servicePlanHouse.detailTitle')}}</p>
            <img v-lazy="{src: require('@/assets/plan-house/P1F.webp')}" alt="" class="img-plan-house">
            <p class="title-border titledetailservice">{{ $t('servicePlanHouse.detailService')}}</p>
            <p class="subtitlePlanHouse">{{ $t('servicePlanHouse.detailService2')}}</p>
            <div class="row row-plan-house">
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/plan-house/แปลนหมู่บ้าน01.webp')}" alt="" class="imgPH">
                </div>
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/plan-house/plan2.webp')}" alt=""  class="imgPH">
                </div>
            </div>
            <p class="servicePlan">{{ $t('servicePlanHouse.service')}}</p>
            <div class="subtitleServicePlan">
                <p>{{ $t('servicePlanHouse.service1')}}</p>
                <p>{{ $t('servicePlanHouse.service2')}}</p>
                <p>{{ $t('servicePlanHouse.service3')}}</p>
                <p>{{ $t('servicePlanHouse.service4')}}</p>
                <p>{{ $t('servicePlanHouse.service5')}}</p>
                <p>{{ $t('servicePlanHouse.service6')}}</p>
                <p>{{ $t('servicePlanHouse.service7')}}</p>
                <p class="margin-p">{{ $t('servicePlanHouse.service8')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>